<template>
  <div class="background">
    <div class="left_box">
      <div class="loginForm">
<!--        <div class="eye">-->
<!--          <i style="font-size:28px;color: #bebebe;margin-bottom: 114px" class="fa">&#xf06e</i>-->
<!--        </div>-->
        <div class="form">
          <div class="passwordLoginForm">
            <p class="title">朗云欢迎您</p>
            <p class="secTitle">通过填写表格登陆您的账户</p>
            <div class="inputArea">
              <p>手机号/邮箱</p>
              <input type="text">
              <div class="input_eye" style="height: 115px">
                <p>修改密码</p>
                <input type="password" >
                <i style="font-size:28px;color: #bebebe;" class="fa">&#xf06e</i>
              </div>
              <div class="input_eye" style="height: 115px">
                <p>确认密码</p>
                <input type="password" >
                <i style="font-size:28px;color: #bebebe;" class="fa">&#xf06e</i>
              </div>
              <p>验证码</p>
              <div>
                <input type="text" style=" height: 30px;
                      width: 100%;
                      margin-bottom: 31px;
                      border-bottom: 2px solid #d4d4d4;
                      margin-top: 24px;
                      float: left;">
                <p style="float: right; color: #ec414d;margin-top: -72px; margin-left: 20px;cursor: pointer">发送验证码</p>
              </div>
              <div style="margin-top: -6px">
                <el-radio-group v-model="radio">
                  <el-checkbox :label="3" style="--el-checkbox-input-border-color-hover: #ec414d">我已阅读并同意《朗云云平台用户服务协议》和《隐私政策声明》
                   </el-checkbox>
                </el-radio-group>
              </div>
            </div>
            <div class="signup">
              <button @click="register">确认修改</button>
            </div>
          </div>
          <div class="codeLogin"></div>
        </div>
      </div>
    </div>
    <div class="rightPic"></div>

  </div>
</template>

<script>

export default {
  name: "ChangePassword",
  data(){
    return{
      radio:1
    }
  }

}
</script>

<style scoped>
.background{
  height: 1040px;
  width: 1920px;

}

.left_box{
  position: absolute;
  width: 960px;
  height: 934px;
  background-image: url("../../images/login_bac1.jpg");
  background-size: 100% 100%;
}

.loginForm{
  position:absolute;
  top: 144px;
  left: 180px;
  width: 656px;
  height: 815px;
  box-shadow: #d4d2d2 0 15px 20px;
  -webkit-box-shadow: #d4d2d2 0 0 20px;
  -moz-box-shadow: #d4d2d2 0 0 20px;
  padding-left: 50px;
  padding-right: 51px;
  padding-top: 55px;
  background-color: #ffffff;
  border-radius: 10px;
}

.loginForm .eye{
  position: absolute;
  right: 50px;
  bottom: 165px;
  cursor: pointer;
}


.loginForm .formTitle{
  width: 556px;
  height: 52px;
  margin: 0 auto;
  border-bottom: 1px solid #979797;
  padding-left: 10px;
}


.loginForm .formTitle div{
  width: 174px;
  height: 52px;
  float: left;
  text-align: center;
  font-size: 24px;
  color: #212121;
  font-weight: 500;
}

.loginForm .formTitle .passwordLoginForm{
  width: 180px;
}

.loginForm .formTitle .passwordLoginForm p{
  float: left;
  width: 179px;
  height: 52px;
  margin-bottom: 26px;
}

.loginForm .formTitle .passwordLoginForm i{
  display: block;
  float: left;
  width: 1px;
  height: 30px;
  background-color: #cccccc;
}

.loginForm .formTitle .passwordLogin p{
  float: left;
  width: 173px;
  height: 52px;
  margin-bottom: 26px;

}

.loginForm .formTitle .passwordLogin i{
  display: block;
  float: left;
  width: 1px;
  height: 30px;
  background-color: #cccccc;
}

.loginForm .formTitle .codeLogin{
  margin-bottom: 26px;
  /*margin-left: 45px;*/
  padding-left: 20px;

}

.loginForm .form{
  font-size: 21px;
  font-weight: 350;
  color: rgba(60, 60, 60, 0.55);
}

.loginForm .form .passwordLoginForm  .title{
  font-size: 36px;
  font-weight: 300;
  color: #212121;
  /*margin-top: 50px;*/
}

.loginForm .form .passwordLoginForm  .secTitle{
  font-size: 18px;
  font-weight: 300;
  margin-top: 22px;
  margin-bottom: 28px;
  color: #3c3c3c;
}

.form .passwordLoginForm .input_area p{
  height: 21px;
}

.form .passwordLoginForm input{
  /*background-color: black;*/
  height: 30px;
  width: 100%;
  margin-bottom: 31px;
  border-bottom: 2px solid #d4d4d4;
  margin-top: 24px;
}

.form .passwordLoginForm .signup{
  display: table;
  margin-top: 38px;
  font-size: 18px;
  font-weight: 400;
}

.form .passwordLoginForm .signup p{
  display: table-cell;
}

.form .passwordLoginForm .signup p:nth-child(2){
  margin-left: 10px;
  color: rgb(235,66,77);
  cursor: pointer;
}

.form .passwordLoginForm button{
  display: table-cell;
  background-color: rgb(235,66,77);
  font-size: 21px;
  width: 138px;
  height: 48px;
  left: 51px;
  line-height: 48px;
  border-radius: 5px;
  margin-left: 418px;
  background-image: linear-gradient(115deg,transparent 50%,#212121 50%);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right bottom;
  color: #ffffff;
  transition: all 1s;
}

.form .passwordLoginForm button:hover{
  background-size: 300%;
}

.form .passwordLoginForm .role{
  height: 20px;
  margin-bottom: 30px;
}

.form .passwordLoginForm .role div{
  float: left;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
}

.form .passwordLoginForm .role div:nth-of-type(1){
  background-color: #ec414d;
}

.form .passwordLoginForm .role div:nth-of-type(2){
  background-color: #212121;
}

.form .passwordLoginForm .role i{
  display: block;
  float: left;
  height: 20px;
  line-height: 20px;
  color: #212121;
}

/*带小眼睛的输入框样式start*/
.input_eye{
  position: relative;
}

.input_eye i{
  position: absolute;
  right: 0px;
  bottom: 36px;
  cursor: pointer;
}
/*带小眼睛的输入框样式end*/
.rightPic{
  position:absolute;
  right:0;
  width:960px;
  height:1040px;
  background-image: url("../../images/login_bac2.jpg");
  background-size: 100% 100%;
}

/*临时加急按钮css*/
.role input{
  margin-top: -5px!important;
  border-radius: 50%!important;
  background-color: #e93d50!important;
}

/*对单选按钮的样式进行修改start*/
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner{
  border-color: #ec414d!important;
  background: #ec414d!important;
}

::v-deep .el-checkbox__input.is-checked+.el-checkbox__label{
  color: #ec414d!important;
}

::v-deep .el-checkbox__inner:hover{
  border-color: #ec414d;
}
/*对单选按钮的样式进行修改end*/
</style>